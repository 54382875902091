import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { get } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import { RootState } from 'store';
import request from 'util/request';

export interface IParamsGetTask {}

export interface IParamsPostTask {
  from: string;
  to: string;
  airline: string;
  date_from: string;
  date_to: string;
  name: string;
  name_en?: string;
  seat_class?: number;
  route_type: number;
}

interface ITask {
  id: number;
  from: string;
  to: string;
  key: number;
  airline: string;
  date_from: string;
  date_to: string;
  name: string;
  name_en: string;
  seat_class: number;
  route_type: number;
}

export interface IInitStateTasks {
  tasks: ITask[];
}

const initialState: IInitStateTasks = {
  tasks: [],
};

export const actionGetTasks = createAsyncThunk(
  'task/actionGetTasks',
  async (params: IParamsGetTask, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/task',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionPostTask = createAsyncThunk(
  'task/actionPostTask',
  async (params: IParamsPostTask[], { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/task',
        method: 'POST',
        data: {
          data: params,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionGetTasks.fulfilled, (state, action) => {
        const tasks = get(action, 'payload.data');
        state.tasks = tasks.map((item: ITask) => {
          const dateFrom = moment(item.date_from).tz('Asia/Seoul').format('YYYY-MM-DD');
          const dateTo = moment(item.date_to).tz('Asia/Seoul').format('YYYY-MM-DD');
          const routeType = item.route_type === 0 ? '직항' : '1회 경유';
          return {
            ...item,
            date_from: dateFrom,
            date_to: dateTo,
            route_type: routeType,
          };
        });
      })
      .addCase(actionPostTask.fulfilled, () => {
        notification.success({
          message: 'Notification',
          description: `Create successfully!`,
        });
      })
      .addCase(actionPostTask.rejected, () => {
        notification.error({
          message: 'Error',
          description: `Create has failed!`,
        });
      });
  },
});

export const selectTasks = (state: RootState) => state.tasks.tasks;

export default slice.reducer;
