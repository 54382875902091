import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'store';
import request from 'util/request';

export interface IParamsGetList {
  from?: string;
  to?: string;
  airline_id?: string;
  date_from?: string;
  date_to?: string;
}

export interface IFlightBaseData {
  date: string;
}

export interface IOptions {
  [key: string]: string;
}

export interface IInitStateFlightBaseData {
  filters: IParamsGetList;
  listFlightBaseData: IFlightBaseData[];
  loading: boolean;
  listFrom: IOptions[];
  listTo: IOptions[];
}

const initialState: IInitStateFlightBaseData = {
  filters: {
    from: '',
    to: '',
    airline_id: '',
    date_from: '',
    date_to: '',
  },
  listFlightBaseData: [],
  listFrom: [],
  listTo: [],
  loading: false,
};

export const actionGetFlightBaseData = createAsyncThunk(
  'flightBaseData/actionGetFlightBaseData',
  async (params: IParamsGetList, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/flight-base-data',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetFromTo = createAsyncThunk(
  'flightBaseData/actionGetFromTo',
  async (search: string, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/flight-base-data/list-from-to',
        method: 'GET',
        params: {
          search,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'flightBaseData',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionGetFlightBaseData.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionGetFlightBaseData.fulfilled, (state, action) => {
        state.loading = false;
        state.listFlightBaseData = get(action, 'payload.data');
      })
      .addCase(actionGetFromTo.fulfilled, (state, action) => {
        const [from, to] = get(action, 'payload.data');
        state.listFrom = from.map((fr: { fbd_from: string }) => ({
          value: fr.fbd_from,
          label: fr.fbd_from,
        }));
        state.listTo = to.map((t: { fbd_to: string }) => ({
          value: t.fbd_to,
          label: t.fbd_to,
        }));
      });
  },
});

export const selectLoading = (state: RootState) => state.flightBaseData.loading;

export const selectFlightBaseData = (state: RootState) => state.flightBaseData.listFlightBaseData;

export const selectListFrom = (state: RootState) => state.flightBaseData.listFrom;

export const selectListTo = (state: RootState) => state.flightBaseData.listTo;

export default slice.reducer;
