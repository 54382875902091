const URL = {
  Login: '/login',
  Home: '/',
  FlightBaseData: '/flight-base-data',
  Data: '/data',
  Rank: '/',
  AirDetails: '/data/rank',
  Task: '/task',
};

export default URL;
