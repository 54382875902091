import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'store';
import request from 'util/request';

export interface IParamsGetDetail {
  date: string;
  ampm: string;
  airline_id?: string;
  to?: string;
  take: number;
  skip: number;
}

const initialState = {
  data: [],
  total: 0,
  loading: false,
};
export const actionGetDetail = createAsyncThunk(
  'airDetail/actionGetDetail',
  async (params: IParamsGetDetail, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/data/rank',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'airDetail',
  initialState,
  reducers: {
    actionInitDataState(state) {
      state.data = initialState.data;
      state.loading = false;
      state.total = 0;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionGetDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = get(action, 'payload.data');
      });
  },
});

export const selectData = (state: RootState) => state.airDetail.data;
export const selectTotal = (state: RootState) => state.airDetail.total;
export const selectLoading = (state: RootState) => state.airDetail.loading;

export default slice.reducer;
