/* eslint-disable react/jsx-props-no-spreading */
import { Space, PageHeader } from 'antd';
import './index.css';
import ConfigManagement from 'crud-with-file-import';
import moment from 'moment';
import { convertTimeExcelToTimeStamp } from 'util/date';

function Task() {
  const preHandleCreate = (data: any) =>
    data.map((item: any) => ({
      airline: item.airline,
      from: item.from,
      to: item.to,
      date_from: `${item.date_from}T00:00.000Z`,
      date_to: `${item.date_to}T00:00.000Z`,
      name: item.name,
      name_en: item.name,
      route_type: item.route_type === '직항' ? 0 : 1,
      seat_class: 0,
    }));

  const preHandleUpdate = (data: any) =>
    data.map((item: any) => ({
      id: item.id,
      airline: item.airline,
      from: item.from,
      to: item.to,
      date_from: `${item.date_from}T00:00.000Z`,
      date_to: `${item.date_to}T00:00.000Z`,
      route_type: item.route_type === '직항' ? 0 : 1,
      seat_class: 0,
    }));

  const postHandleRead = (tasks: any, columns: any) =>
    tasks.map((item: any) => {
      const dateFrom = moment(item.date_from).tz('Asia/Seoul').format('YYYY-MM-DD');
      const dateTo = moment(item.date_to).tz('Asia/Seoul').format('YYYY-MM-DD');
      const routeType = item.route_type === 0 ? '직항' : '1회 경유';
      return {
        ...item,
        date_from: dateFrom,
        date_to: dateTo,
        route_type: routeType,
      };
    });

  const postHandleReadFile = (tasks: any, columns: any) =>
    tasks.map((item: any) => {
      const dateFrom = moment(convertTimeExcelToTimeStamp(item.date_from)).tz('Asia/Seoul').format('YYYY-MM-DD');
      const dateTo = moment(convertTimeExcelToTimeStamp(item.date_to)).tz('Asia/Seoul').format('YYYY-MM-DD');
      return {
        ...item,
        date_from: dateFrom,
        date_to: dateTo,
      };
    });

  return (
    <>
      <PageHeader backIcon={null} title="Task" />
      <Space direction="vertical" style={{ display: 'flex' }}>
        <ConfigManagement
          columns={[
            {
              title: '순서',
              dataIndex: 'id',
              editable: false,
            },
            {
              title: '출발지',
              dataIndex: 'from',
              editable: true,
            },
            {
              title: '도착지',
              dataIndex: 'to',
              editable: true,
            },
            {
              title: '출발일',
              dataIndex: 'date_from',
              editable: true,
            },
            {
              title: '도착일',
              dataIndex: 'date_to',
              editable: true,
            },
            {
              title: '여정',
              dataIndex: 'route_type',
              editable: true,
            },
            {
              title: '항공사명',
              dataIndex: 'name',
              editable: true,
            },
            {
              title: '항공사코드',
              dataIndex: 'airline',
              editable: true,
            },
          ]}
          apiHost={process.env.REACT_APP_API}
          apiReadPath="task"
          apiUpdatePath="task"
          apiCreatePath="task"
          preHandleUpdate={preHandleUpdate}
          preHandleCreate={preHandleCreate}
          postHandleRead={postHandleRead}
          postHandleReadFile={postHandleReadFile}
          index="id"
          importMode="w"
          isNotShowRowSelection
          isNotShowBtnAdd
          isNotShowBtnDelete
        />
      </Space>
    </>
  );
}
export default Task;
