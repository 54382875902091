import DefaultLayout from 'app/layouts/DefaultLayout';
import PrivateRoute from 'app/layouts/PrivateRouter';
import Data from 'app/pages/Data';
import NotFound from 'app/pages/Results/NotFound';
import URL from 'constants/url';
import { lazy, ReactElement, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AirDetails from 'app/pages/Rank/airDetails';
import Rank from 'app/pages/Rank';
import Task from 'app/pages/Task';

const DEFAULT_LAYOUT = 'default';
const NONE_LAYOUT = 'none';

const Login = lazy(() => import('app/pages/Login/index'));

interface ItemType {
  key: string;
  components: ReactElement;
  layout: string;
  private: boolean;
}

const userItems: ItemType[] = [];

const adminItems: ItemType[] = [
  {
    key: URL.Rank,
    components: <Rank />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.Task,
    components: <Task />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.AirDetails,
    components: <AirDetails />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.Data,
    components: <Data />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
];

const sharedItems: ItemType[] = [
  {
    key: URL.Login,
    components: <Login />,
    layout: NONE_LAYOUT,
    private: false,
  },
  {
    key: '/',
    components: <Navigate to={URL.Rank} />,
    layout: NONE_LAYOUT,
    private: false,
  },
  {
    key: '/task',
    components: <Navigate to={URL.Task} />,
    layout: NONE_LAYOUT,
    private: false,
  },
  {
    key: '*',
    components: <NotFound />,
    layout: NONE_LAYOUT,
    private: false,
  },
];

function getItems(isTargetAdmin: boolean) {
  const items = isTargetAdmin ? adminItems.concat(sharedItems) : userItems.concat(sharedItems);
  return items;
}

export default function Routers() {
  const items = getItems(true);
  return (
    <Routes>
      {items.map((item) => {
        let element = item.components;

        element = <Suspense fallback={null}>{element}</Suspense>;

        if (item.layout === DEFAULT_LAYOUT) {
          element = <DefaultLayout>{element}</DefaultLayout>;
        }

        if (item.private) {
          element = <PrivateRoute>{element}</PrivateRoute>;
        }

        return <Route key={item.key} path={item.key} element={element} />;
      })}
    </Routes>
  );
}
