import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import request from 'util/request';
import { RootState } from 'store';

export interface IDataQuery {
  date: string;
  ampm: string;
  from: string;
  to: string;
  airline_id: string;
  date_from: string;
  date_to: string;
  site: number;
}

export interface IDataNaver {
  data: any[];
  flightBaseData: any;
}

export interface IDataSkyScanner {
  data: any[];
  flightBaseData: any;
}

export interface IInitStateData {
  loading: boolean;
  data: unknown[];
  dataNaver: IDataNaver;
  dataSkyScanner: IDataSkyScanner;
  dataDetails: unknown[];
}

const initialState: IInitStateData = {
  data: [],
  dataNaver: {
    data: [],
    flightBaseData: [],
  },
  dataSkyScanner: {
    data: [],
    flightBaseData: [],
  },
  loading: false,
  dataDetails: [],
};

export const actionGetData = createAsyncThunk('data/actionGetData', async (params: IDataQuery, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/data',
      method: 'GET',
      params,
    });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    actionInitDataState(state) {
      state.data = initialState.data;
      state.loading = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetData.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionGetData.fulfilled, (state, action) => {
        state.loading = false;
        const res = get(action, 'payload.data');
        if (action.payload.config.params.site === 1) {
          state.dataSkyScanner = res;
        }
        if (action.payload.config.params.site === 2) {
          state.dataNaver = res;
        }
        // if()
        state.data = get(action, 'payload.data');
      });
  },
});

export const { actionInitDataState } = slice.actions;

export const selectDataNaver = (state: RootState) => state.data.dataNaver;
export const selectDataSkyScanner = (state: RootState) => state.data.dataSkyScanner;
export const selectLoading = (state: RootState) => state.data.loading;

export default slice.reducer;
