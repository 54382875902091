import { DATE_FORMAT } from 'constants/app';
import moment from 'moment';

export const separateDate = (date: Date[] | undefined, pattern?: string) => {
  if (!date) return { startDate: '', endDate: '' };
  const startDate = moment(date[0]).format(pattern ?? DATE_FORMAT);
  const endDate = moment(date[1]).format(pattern ?? DATE_FORMAT);
  return { startDate, endDate };
};

export const convertTimeExcelToTimeStamp = (timeNumber: number) =>
  moment(Math.round((timeNumber - 25569) * 86400 * 1000))
    .utc()
    .format();

export default {};
