import { Form, PageHeader, Space, Table, Card, Select, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { actionGetAirline, selectAirline, IAirline } from 'store/airlineSlice';
import { actionGetTasks, selectTasks } from 'store/tasks';
import {
  actionGetData,
  actionInitDataState,
  selectDataNaver,
  selectDataSkyScanner,
  selectLoading,
} from 'store/dataSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { actionGetFromTo, selectListTo } from 'store/flightBaseDataSlice';
import { generateQueryParamstoObject } from 'util/url';
import moment from 'moment';
import { formatPrice } from 'util/format';
import { SearchOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { NAVER_LINK, SKYSCANNER_LINK } from 'constants/websites';
import { StoreValue } from 'antd/lib/form/interface';
import { cloneDeep } from 'lodash';
import './index.css';

interface DataType {
  key: React.Key;
  from: string;
  to: string;
  fromDate: string;
  toDate: string;
  meta: any;
}

interface IDataSort {
  key: React.Key;
  supplier: string;
  card: string;
  price: number;
  discount: string;
}

const findCurrentAirlineName = (airlineOptions: { value: string; label: string }[], airlineState: string) => {
  const currentAirlineOption = airlineOptions.find((item) => item.value === airlineState);
  const airlineName = currentAirlineOption ? currentAirlineOption.label : '';

  return airlineName;
};

const getAirlineOptions = (airlines: IAirline[]) => {
  const airlinesTmp = cloneDeep(airlines);
  const newAirlines = airlinesTmp.map((newAirline) => ({
    value: newAirline.id,
    label: newAirline.name,
  }));
  return newAirlines;
};

const fromDateStringToTimeFlight = (dateString: string) => {
  if (!dateString) return '';
  const gettedDate = new Date(dateString);
  const hours = String(gettedDate.getHours()).padStart(2, '0');
  const minutes = String(gettedDate.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
};

const fromDateStringToTimeCrawl = (dateString: string) => {
  if (!dateString) return '';
  const gettedDate = new Date(dateString);

  return gettedDate.toLocaleString().replace(',', '');
};

const fromDateStringToDateFlight = (dateString: string) => {
  if (!dateString) return '';
  const gettedDate = new Date(dateString);
  const year = gettedDate.getFullYear();
  const month = String(gettedDate.getMonth() + 1).padStart(2, '0');
  const day = String(gettedDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const fromMinuteToDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h${remainingMinutes}m`;
};

function Data() {
  const [formSearch] = Form.useForm();
  const [form] = Form.useForm();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const listTo = useAppSelector(selectListTo);
  const tasks = useAppSelector(selectTasks);
  const [naverUpdatedAt, setNaverUpdatedAt] = useState<any>('');
  const [skyScannerUpdatedAt, setSkyScannerUpdatedAt] = useState<any>('');
  const [dataFromTo, setDataFromTo] = useState({
    key: 'from_to_1',
    from: '?',
    to: '?',
    fromDate: '?',
    toDate: '?',
  });
  const [naverTableYScroll, setNaverTableYScroll] = useState(window.innerHeight - 250);
  const [skyScannerTableYScroll, setSkyScannerTableYScroll] = useState(window.innerHeight - 250);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const { date, ampm, from, airline } = params;
  const [toState, setToState] = useState('');
  const [dataFromToSkyScanner, setDataFromToSkyScanner] = useState<any>({
    key: 'from_to_1',
    from: '?',
    to: '?',
    fromDate: '?',
    toDate: '?',
  });
  const [dataFromToNaver, setDataFromToNaver] = useState<any>({
    key: 'from_to_1',
    from: '?',
    to: '?',
    fromDate: '?',
    toDate: '?',
  });

  const [fromToDisplaySkyScanner, setFromToDisplaySkyScanner] = useState('');
  const [toFromDisplaySkyScanner, setToFromDisplaySkyScanner] = useState('');
  const [fromToDisplayNaver, setFromToDisplayNaver] = useState('');
  const [toFromDisplayNaver, setToFromDisplayNaver] = useState('');

  const airlines = useAppSelector(selectAirline);
  const [airlineOptions, setAirlineOptions] = useState<{ value: string; label: string }[]>(getAirlineOptions(airlines));
  const [airlineState, setAirlineState] = useState(airline);
  const [currentAirlineName, setCurrentAirlineName] = useState(findCurrentAirlineName(airlineOptions, airlineState));
  const navigate = useNavigate();
  const dataApiNaver = useAppSelector<any>(selectDataNaver);
  const dataApiSkyscanner = useAppSelector<any>(selectDataSkyScanner);
  //
  const hash = Object.fromEntries(Object.values(airlines).map((e) => [e.id, e.skyscanner_code]));
  const mapAirline = new Map(Object.entries(hash));

  const paramsQueryAirline = generateQueryParamstoObject(search).airline;
  const skyscanner_code = mapAirline.get(paramsQueryAirline);
  // State component
  const [dataSortPriceCardNaver, setDataSortPriceCardNaver] = useState<IDataSort[]>([]);
  const [dataSortPriceSupplierNaver, setDataSortPriceSupplierNaver] = useState<IDataSort[]>([]);

  const [dataSortPriceSupplierSkyscanner, setDataSortPriceSupplierSkyscanner] = useState<IDataSort[]>([]);

  const columnsChild = [
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
    },
    {
      title: 'Card',
      dataIndex: 'card',
      key: 'card',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: number, record: IDataSort) => <span>{formatPrice(record.price)}</span>, // note render
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
    },
  ];

  const handleRenderLinkNaver = (record: { from: string; to: string; fromDate: string; toDate: string }) =>
    `${NAVER_LINK}${record.from}-${record.to}-${moment(record.fromDate).format('YYYYMMDD')}/${record.to}-${
      record.from
    }-${moment(record.toDate).format('YYYYMMDD')}?adult=1&fareType=Y`;

  const hanldeRenderLinkSkyscanner = (record: { from: string; to: string; fromDate: string; toDate: string }) =>
    `${SKYSCANNER_LINK}${record.from.toLowerCase()}/${record.to.toLowerCase()}/${moment(record.fromDate).format(
      'YYMMDD'
    )}/${moment(record.toDate).format(
      'YYMMDD'
    )}/?adultsv2=1&airlines=-${skyscanner_code}&cabinclass=economy&childrenv2=&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&rtn=1`;

  const handleSkyScannerClickFlightInfo = (isFromTo: boolean) => {
    if (isFromTo) {
      setFromToDisplaySkyScanner(fromToDisplaySkyScanner === '' ? 'hidden' : '');
    } else {
      setToFromDisplaySkyScanner(toFromDisplaySkyScanner === '' ? 'hidden' : '');
    }
  };

  const handleNaverClickFlightInfo = (isFromTo: boolean) => {
    if (isFromTo) {
      setFromToDisplayNaver(fromToDisplayNaver === '' ? 'hidden' : '');
    } else {
      setToFromDisplayNaver(toFromDisplayNaver === '' ? 'hidden' : '');
    }
  };

  const getDestinationIndexFromAllFlight = (allFlight: any) => {
    if (allFlight.length % 2 === 0 && allFlight.length > 0) {
      const destionationIndex = allFlight.length / 2 - 1;
      return destionationIndex;
    }
    // length should be 3 by assumtion
    if (allFlight.length > 0) {
      let biggestDuration = 0;
      let biggestDurationIndex = -1;
      allFlight.forEach((element: any, index: number) => {
        if (element.duration > biggestDuration) {
          biggestDuration = element.duration;
          biggestDurationIndex = index;
        }
      });

      if (biggestDurationIndex !== 0) {
        return 1;
      }
    }

    return 0;
  };

  const getNoMetaTableByRecord = (record: any) => {
    const paramsQuery = generateQueryParamstoObject(search);

    return (
      <div>
        <h3>
          {record ? record?.from : paramsQuery?.from} - {record ? record?.fromDate : paramsQuery?.fromDate}
        </h3>
        <h3>
          {record ? record?.to : paramsQuery?.to} - {record ? record?.toDate : paramsQuery?.toDate}
        </h3>
      </div>
    );
  };

  const getFlyGeneralDetailComponent = (
    record: any,
    meta: any,
    item: any,
    index: number,
    clickFlightInfoHanler: any,
    renderLinkHandler: any,
    isFromToDisplay: any,
    isToFromDisplay: any
  ) => (
    <div className="meta-table-container">
      {index === 0 ? (
        <h3>가는날 출발시간: {fromDateStringToDateFlight(meta.date_from)}</h3>
      ) : (
        <h3>오는날 출발시간: {fromDateStringToDateFlight(meta.date_to)}</h3>
      )}
      {item.length > 0 && (
        <button
          type="button"
          className="flight-infor"
          onClick={() => {
            clickFlightInfoHanler(index === 0);
          }}
        >
          <a className="from" href={renderLinkHandler(record)} target="_blank" rel="noreferrer">
            {meta && meta.metaDatas.length > 0 && <div>{fromDateStringToTimeFlight(item[0]?.departure)}</div>}
            <div>{record?.from}</div>
          </a>
          <button className="bridge" type="button">
            <div className="border-me" />
            {item.length > 1 && <div className="dot-me" />}
            <div className="border-me" />
          </button>
          <a className="to" href={renderLinkHandler(record)} target="_blank" rel="noreferrer">
            {meta && meta.metaDatas.length > 0 && (
              <div>{fromDateStringToTimeFlight(item[item.length - 1].arrival)}</div>
            )}
            <div>{record?.to}</div>
          </a>
          <div className="card-outlined">
            {index === 0 && isFromToDisplay && <DownOutlined />}
            {index === 0 && !isFromToDisplay && <UpOutlined />}
            {index !== 0 && isToFromDisplay && <DownOutlined />}
            {index !== 0 && !isToFromDisplay && <UpOutlined />}
          </div>
        </button>
      )}
      {item.length > 0 && (
        <div className={`transit-detail-container ${index === 0 ? isFromToDisplay : isToFromDisplay}`}>
          {meta &&
            meta.metaDatas.length > 0 &&
            item.map((e: any) => (
              <div className="transit-detail">
                <div className="airline-name">{meta.airline_id + e.flight_number}</div>
                <div className="detail">
                  <div className="duration">{fromMinuteToDuration(e.duration)}</div>
                  <div className="detail-from-to">
                    <div className="from-to">
                      <div className="time-flight">{fromDateStringToTimeFlight(e?.departure)}</div>
                      <div>
                        {e?.from.code} {e?.from.name}
                      </div>
                    </div>
                    <div className="from-to">
                      <div className="time-flight">{fromDateStringToTimeFlight(e?.arrival)}</div>
                      <div>
                        {e?.to?.code} {e?.to?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );

  const columnsNaver: ColumnsType<DataType> = [
    {
      title: '노선',
      dataIndex: 'from_to',
      key: 'from_to',
      width: '30%',
      onCell: (record: { from: string; to: string; fromDate: string; toDate: string }) => ({
        onClick: () => handleRenderLinkNaver(record),
      }),
      render: (_from_to: string, record: DataType) => {
        const { meta } = record;
        const noMetaDataTable = getNoMetaTableByRecord(record);
        if (!meta || meta.metaDatas.length === 0) {
          return noMetaDataTable;
        }
        const oneWayFlights = [];
        const allFlights = cloneDeep(meta.metaDatas[0].metadata);
        const destinationIndex = getDestinationIndexFromAllFlight(allFlights);
        const flightFromTo = allFlights.slice(0, destinationIndex + 1);
        const flightToFrom = allFlights.slice(destinationIndex + 1, allFlights.length);
        oneWayFlights.push(flightFromTo);
        oneWayFlights.push(flightToFrom);
        if (oneWayFlights.length === 0) {
          return noMetaDataTable;
        }
        return (
          <div className="meta-table">
            {oneWayFlights &&
              oneWayFlights.map((item: any, index: number) => (
                <Card
                  style={{
                    width: '100%',
                    marginBottom: index === 0 ? '10px' : '0',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {getFlyGeneralDetailComponent(
                    record,
                    meta,
                    item,
                    index,
                    handleNaverClickFlightInfo,
                    handleRenderLinkNaver,
                    fromToDisplayNaver,
                    toFromDisplayNaver
                  )}
                </Card>
              ))}
          </div>
        );
      },
    },
    {
      title: `네이버 ${naverUpdatedAt ? fromDateStringToTimeCrawl(naverUpdatedAt) : naverUpdatedAt}`,
      children: [
        {
          title: '여행사별 최저가',
          width: '35%',
          align: 'center',
          render: () => (
            <Table
              className="sort-supplier-table"
              loading={loading}
              dataSource={dataSortPriceSupplierNaver}
              columns={columnsChild}
              showHeader={false}
              pagination={false}
              scroll={{ y: naverTableYScroll }}
            />
          ),
        },
        {
          title: '카드사별 할인율',
          width: '35%',
          align: 'center',
          render: () => (
            <Table
              className="sort-card-table"
              loading={loading}
              dataSource={dataSortPriceCardNaver}
              columns={columnsChild}
              showHeader={false}
              pagination={false}
              scroll={{ y: naverTableYScroll }}
            />
          ),
        },
      ],
    },
  ];

  const columnsSkyscanner: ColumnsType<DataType> = [
    {
      title: '노선',
      dataIndex: 'from_to',
      key: 'from_to',
      width: '30%',
      onCell: (record: { from: string; to: string; fromDate: string; toDate: string }) => ({
        onClick: () => hanldeRenderLinkSkyscanner(record),
      }),
      render: (_from_to: string, record: DataType) => {
        const { meta } = record;
        const noMetaDataTable = getNoMetaTableByRecord(record);
        if (!meta || meta.metaDatas.length === 0) {
          return noMetaDataTable;
        }
        const oneWayFlights = [];
        const allFlights = cloneDeep(meta.metaDatas[0].metadata);
        const flightToDestinationIndex = allFlights.findIndex((item: any) => item.to.code === record.to);
        const flightFromTo = allFlights.slice(0, flightToDestinationIndex + 1);
        const flightToFrom = allFlights.slice(flightToDestinationIndex + 1, allFlights.length);
        oneWayFlights.push(flightFromTo);
        oneWayFlights.push(flightToFrom);
        if (oneWayFlights.length === 0) {
          return noMetaDataTable;
        }
        return (
          <div className="meta-table">
            {oneWayFlights &&
              oneWayFlights.map((item: any, index: number) => (
                <Card
                  style={{
                    width: '100%',
                    marginBottom: index === 0 ? '10px' : '0',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {getFlyGeneralDetailComponent(
                    record,
                    meta,
                    item,
                    index,
                    handleSkyScannerClickFlightInfo,
                    hanldeRenderLinkSkyscanner,
                    fromToDisplaySkyScanner,
                    toFromDisplaySkyScanner
                  )}
                </Card>
              ))}
          </div>
        );
      },
    },
    {
      title: `스카이스캐너 ${fromDateStringToTimeCrawl(skyScannerUpdatedAt)}`,
      align: 'center',
      render: () => (
        <Table
          className="sort-supplier-table"
          loading={loading}
          dataSource={dataSortPriceSupplierSkyscanner}
          columns={columnsChild}
          showHeader={false}
          pagination={false}
          scroll={{ y: skyScannerTableYScroll }}
        />
      ),
    },
  ];

  const handleSearchByCondition = (store: StoreValue) => {
    const currentAirLine = store.airline == null ? '' : store.airline;
    const currentTo = store.to == null ? '' : store.to;
    setAirlineState(currentAirLine);
    setToState(currentTo);
    dispatch(actionGetTasks({}));
  };

  useEffect(() => {
    const searchedTask = tasks.find((item) => item.airline === airlineState && item.to === toState);
    if (searchedTask) {
      const url = new URLSearchParams({
        fromDate: searchedTask.date_from,
        toDate: searchedTask.date_to,
        airline: airlineState,
        from,
        to: toState,
      }).toString();
      navigate({
        pathname: '/data',
        search: `?${url}&date=${date}&ampm=${ampm}`,
      });
    } else {
      setDataSortPriceCardNaver([]);
      setDataSortPriceSupplierNaver([]);
      setDataSortPriceSupplierSkyscanner([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  useEffect(() => {
    const airlineName = findCurrentAirlineName(airlineOptions, airlineState);
    if (airlineName) {
      setCurrentAirlineName(airlineName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airlineState]);

  useEffect(() => {
    dispatch(actionGetAirline());
    dispatch(actionGetFromTo(''));
  }, [dispatch]);

  useEffect(() => {
    if (dataApiNaver.data.length) {
      let newSortSupplierNaver = dataApiNaver.data.map((item: any, index: number) => ({
        key: `sort-supplier-${index}`,
        supplier: item.supplierInfo.name,
        card: item.cardInfo.name,
        price: item.discount_price,
        discount: `${(
          ((dataApiNaver.flightBaseData.price - item.discount_price) * 100) /
          dataApiNaver.flightBaseData.price
        ).toFixed(2)}%`,
      }));
      newSortSupplierNaver = Object.values(
        newSortSupplierNaver.reduce((r: any, o: any) => {
          // eslint-disable-next-line no-param-reassign
          r[o.supplier] = r[o.supplier] && r[o.supplier].price < o.price ? r[o.supplier] : o;
          return r;
        }, {})
      );
      let newSortCardNaver = dataApiNaver.data.map((item: any, index: number) => ({
        key: `sort-card-${index}`,
        supplier: item.supplierInfo.name,
        card: item.cardInfo.name,
        price: item.discount_price,
        discount: `${(
          ((dataApiNaver.flightBaseData.price - item.discount_price) * 100) /
          dataApiNaver.flightBaseData.price
        ).toFixed(2)}%`,
      }));
      newSortCardNaver = Object.values(
        newSortCardNaver.reduce((r: any, o: any) => {
          // eslint-disable-next-line no-param-reassign
          r[o.card] = r[o.card] && r[o.card].price < o.price ? r[o.card] : o;
          return r;
        }, {})
      );

      setDataSortPriceSupplierNaver(newSortSupplierNaver);
      setDataSortPriceCardNaver(newSortCardNaver);
      setNaverUpdatedAt(dataApiNaver.data[0].updated_at);
      setDataFromToNaver({
        ...dataFromTo,
        meta: dataApiNaver.flightBaseData,
      });
    } else {
      setDataSortPriceSupplierNaver([]);
      setDataSortPriceCardNaver([]);
      setDataFromToNaver({
        ...dataFromTo,
      });
    }
  }, [dataApiNaver, dataFromTo]);

  useEffect(() => {
    if (dataApiSkyscanner.data.length) {
      let newSortSupplierSkyscanner = dataApiSkyscanner.data.map((item: any, index: number) => ({
        key: `sort-supplier-${index}`,
        supplier: item.supplierInfo.name,
        card: item.cardInfo.name,
        price: item.discount_price,
        discount: `${(
          ((dataApiSkyscanner.flightBaseData.price - item.discount_price) * 100) /
          dataApiSkyscanner.flightBaseData.price
        ).toFixed(2)}%`,
      }));
      newSortSupplierSkyscanner = Object.values(
        newSortSupplierSkyscanner.reduce((r: any, o: any) => {
          // eslint-disable-next-line no-param-reassign
          r[o.supplier] = r[o.supplier] && r[o.supplier].price < o.price ? r[o.supplier] : o;
          return r;
        }, {})
      );

      setDataSortPriceSupplierSkyscanner(newSortSupplierSkyscanner);
      setSkyScannerUpdatedAt(dataApiSkyscanner.data[0].updated_at);
      setDataFromToSkyScanner({
        ...dataFromTo,
        meta: dataApiSkyscanner.flightBaseData,
      });
    } else {
      setDataSortPriceSupplierSkyscanner([]);
      setDataFromToSkyScanner({
        ...dataFromTo,
      });
    }
  }, [dataApiSkyscanner, dataFromTo]);

  useEffect(() => {
    const paramsQuery = generateQueryParamstoObject(search);
    setDataFromTo({
      key: 'from_to_1',
      to: paramsQuery.to ?? '?',
      from: paramsQuery.from ?? '?',
      toDate: paramsQuery.toDate ? moment(paramsQuery.toDate).format('YYYY/MM/DD') : '?',
      fromDate: paramsQuery.fromDate ? moment(paramsQuery.fromDate).format('YYYY/MM/DD') : '?',
    });
    if (paramsQuery.fromDate && paramsQuery.toDate && paramsQuery.airline && paramsQuery.from && paramsQuery.to) {
      dispatch(
        actionGetData({
          date: paramsQuery.date,
          ampm: paramsQuery.ampm,
          from: paramsQuery.from,
          to: paramsQuery.to,
          airline_id: paramsQuery.airline,
          date_from: paramsQuery.fromDate,
          date_to: paramsQuery.toDate,
          site: 2,
        })
      );
      dispatch(
        actionGetData({
          date: paramsQuery.date,
          ampm: paramsQuery.ampm,
          from: paramsQuery.from,
          to: paramsQuery.to,
          airline_id: paramsQuery.airline,
          date_from: paramsQuery.fromDate,
          date_to: paramsQuery.toDate,
          site: 1,
        })
      );
    }
  }, [dispatch, search, formSearch]);

  useEffect(
    () => () => {
      dispatch(actionInitDataState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (airlines.length) {
      setAirlineOptions(getAirlineOptions(airlines));
    }
  }, [airlines]);

  return (
    <>
      <PageHeader backIcon={null} title={currentAirlineName} />
      <Space>
        <Card>
          <Form form={form} layout="vertical" onFinish={handleSearchByCondition}>
            <Space className="h-full items-start">
              <Form.Item label="항공사" name="airline" className="w-[250px]">
                <Select
                  showSearch
                  allowClear
                  placeholder="항공사를 선택하세요"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={airlineOptions}
                />
              </Form.Item>
              <Form.Item label="도착지" name="to" className="w-[150px]">
                <Select
                  showSearch
                  allowClear
                  placeholder="도착지를 선택하세요"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={listTo}
                />
              </Form.Item>
              <Form.Item label={<span />}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  검색
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>
      </Space>
      <Space direction="vertical">
        <Table columns={columnsNaver} dataSource={[dataFromToNaver]} bordered pagination={false} />
        <Table columns={columnsSkyscanner} dataSource={[dataFromToSkyScanner]} bordered pagination={false} />
      </Space>
    </>
  );
}
export default Data;
