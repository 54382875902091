import { createSlice } from '@reduxjs/toolkit';

interface AuthState {}

const initialState: AuthState = {};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    actionLogout() {
      // TODO: implement action logout
    },
  },

  extraReducers: () => {
    // builder.
    //   // LOGIN ADMIN
  },
});

export const { actionLogout } = slice.actions;

export default slice.reducer;
