import { DatabaseOutlined, TabletOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import URL from 'constants/url';
import logoImage from 'app/images/logo.png';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];
function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Rank', URL.Rank, <DatabaseOutlined />),
  getItem('Task', URL.Task, <TabletOutlined />),
];

function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);

  function handleSelectItem(data: { key: string }) {
    navigate(data.key);
  }

  function handleClick() {
    navigate('/');
  }

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div tabIndex={0} onMouseDown={handleClick} style={{ height: 32, margin: 16 }} role="button">
        <img src={logoImage} alt="" width="100%" />
      </div>
      <Menu theme="dark" mode="inline" items={items} defaultSelectedKeys={[pathname]} onSelect={handleSelectItem} />
    </Sider>
  );
}

export default Sidebar;
