import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Table, { ColumnsType } from 'antd/lib/table';
import { actionGetDetail, selectData, selectLoading, selectTotal } from 'store/airdetailSlice';
import { useAppDispatch, useAppSelector } from 'store';
import moment from 'moment';
import { Button, Card, Form, PageHeader, Pagination, PaginationProps, Select, Space, Tooltip } from 'antd';
import { SearchOutlined, WarningOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { actionGetFromTo, selectListTo } from 'store/flightBaseDataSlice';
import { cloneDeep } from 'lodash';
import { StoreValue } from 'antd/lib/form/interface';
import { actionGetAirline, selectAirline } from 'store/airlineSlice';

interface IRoom {
  day: number;
  rank: number;
  rankDelta: string | number;
  textAlign: string;
}
interface IDataType {
  key: React.Key;
  flight: string;
  ranks: IRoom[];
}
function CustomWarningOutlined() {
  return (
    <Tooltip title=" 순위가 불확실하여 확인 필요">
      <WarningOutlined />
    </Tooltip>
  );
}

interface IFlightRankObject {
  from: string;
  to: string;
  airlineId: string;
  dateFrom: string;
  dateTo: string;
  date: string;
  rankingInNaverBySupplier: number;
  rankingInNaverByCard: number;
  rankingInSkyscanner: number;
  isNaverResultReliable: boolean;
  isSkyscannerResultReliable: boolean;
}

const formatFlight = (text: string) => {
  const airline = text.split(',')[2];
  const from = text.split(',')[0];
  const to = text.split(',')[1];
  const dateFrom = moment(text.split(',')[3]).format('YYYY-MM-DD');
  const dateReturn = moment(text.split(',')[4]).format('YYYY-MM-DD');
  return {
    fromDate: dateFrom,
    toDate: dateReturn,
    airline,
    from,
    to,
  };
};

const getDayDiff = (date1: Date, date2: Date): number =>
  Math.ceil(Math.abs(Number(date1.setHours(0, 0, 0, 0)) - Number(date2.setHours(0, 0, 0, 0))) / (24 * 60 * 60 * 1000));

const makeKey = (flightRankObject: IFlightRankObject) => {
  const { from, to, airlineId, dateFrom, dateTo } = flightRankObject;
  return `${from},${to},${airlineId},${new Date(dateFrom).toISOString()},${new Date(dateTo).toISOString()}`;
};

const transformFlightRank = (datas: any, date: Date) => {
  const flightMap = new Map();
  datas.forEach((data: IFlightRankObject) => {
    const key = makeKey(data);
    if (!flightMap.has(key)) {
      flightMap.set(
        key,
        Array(6).fill({
          rankingInNaverBySupplier: 0,
          rankingInNaverByCard: 0,
          rankingInSkyscanner: 0,
          isNaverResultReliable: false,
          isSkyscannerResultReliable: false,
        })
      );
    }
    const dayDiff = getDayDiff(date, new Date(data.date));
    flightMap.get(key)[dayDiff] = {
      rankingInNaverBySupplier: data.rankingInNaverBySupplier,
      rankingInNaverByCard: data.rankingInNaverByCard,
      rankingInSkyscanner: data.rankingInSkyscanner,
      isNaverResultReliable: data.isNaverResultReliable,
      isSkyscannerResultReliable: data.isSkyscannerResultReliable,
    };
  });
  const res: IDataType[] = [];
  flightMap.forEach((value, key) => {
    res.push({
      flight: key,
      ranks: value,
      key,
    });
  });
  return res;
};

function AirDetails() {
  const take = 100;
  const [form] = Form.useForm();
  const [currentSkip, setCurrentSkip] = useState(0);
  const airlineRank = useAppSelector(selectData);
  const airlineRankTotal = useAppSelector(selectTotal);
  const listTo = useAppSelector(selectListTo);
  const airlines = useAppSelector(selectAirline);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const { site, airline_id, to, date, ampm } = params;
  const [siteState, setSiteState] = useState(site == null ? 2 : parseInt(site, 10));
  const [airlineOptions, setAirlineOptions] = useState<{ value: string; label: string }[]>([]);
  const [airlineState, setAirlineState] = useState(airline_id == null ? '' : airline_id);
  const [toState, setToState] = useState(to == null ? '' : to);
  const [dataSource, setDataSource] = useState<IDataType[]>([]);

  if (airline_id) {
    form.setFieldsValue({ airline: airline_id });
  }
  if (to) {
    form.setFieldsValue({ to });
  }
  if (site) {
    form.setFieldsValue({ site: siteState });
  }
  const renderRankDelta = (ranks: any) => {
    const rankNaverBySupplierDelta: any =
      ranks[1].rankingInNaverBySupplier !== 0 && ranks[0].rankingInNaverBySupplier !== 0
        ? [
            ranks[0].rankingInNaverBySupplier,
            ranks[1].rankingInNaverBySupplier,
            ranks[1].rankingInNaverBySupplier - ranks[0].rankingInNaverBySupplier,
          ]
        : 'NA';
    const rankNaverByCardDelta: any =
      ranks[1].rankingInNaverByCard !== 0 && ranks[0].rankingInNaverByCard !== 0
        ? [
            ranks[0].rankingInNaverByCard,
            ranks[1].rankingInNaverByCard,
            ranks[1].rankingInNaverByCard - ranks[0].rankingInNaverByCard,
          ]
        : 'NA';
    const rankSkyscannerDelta: any =
      ranks[1].rankingInSkyscanner !== 0 && ranks[0].rankingInSkyscanner !== 0
        ? [
            ranks[0].rankingInSkyscanner,
            ranks[1].rankingInSkyscanner,
            ranks[1].rankingInSkyscanner - ranks[0].rankingInSkyscanner,
          ]
        : 'NA';
    return [rankNaverBySupplierDelta, rankNaverByCardDelta, rankSkyscannerDelta];
  };
  const renderRankDeltaStyle = (rankDelta: any) => {
    if (rankDelta === 'NA') return { color: 'black', sign: '', text: 'NA' };
    if (rankDelta[2] === 0) return { color: 'black', sign: '', text: '0' };
    if (rankDelta[1] - rankDelta[0] < 0)
      return { color: 'blue', sign: '', text: `${rankDelta[2]}(${rankDelta[1]} -> ${rankDelta[0]})` };
    return { color: 'red', sign: '+', text: `${rankDelta[2]}(${rankDelta[1]} -> ${rankDelta[0]})` };
  };
  const renderRankDayCell = (ranks: any[], index: number) => {
    const rankingInNaverBySupplier =
      ranks[index].rankingInNaverBySupplier !== 0 ? ranks[index].rankingInNaverBySupplier : 'NA';
    const rankingInNaverByCard = ranks[index].rankingInNaverByCard !== 0 ? ranks[index].rankingInNaverByCard : 'NA';
    const rankingInSkyscanner = ranks[index].rankingInSkyscanner !== 0 ? ranks[index].rankingInSkyscanner : 'NA';
    const { isNaverResultReliable, isSkyscannerResultReliable } = ranks[index];
    const renderRankAlign = 'right';
    return [
      renderRankAlign,
      rankingInNaverBySupplier,
      rankingInNaverByCard,
      rankingInSkyscanner,
      isNaverResultReliable,
      isSkyscannerResultReliable,
    ];
  };

  const columns: ColumnsType<IDataType> = [
    {
      title: '항공사',
      render: (flight: any, record: any) => formatFlight(record.flight).airline,
    },
    {
      title: '출발지',
      render: (flight: any, record: any) => formatFlight(record.flight).from,
    },
    {
      title: '도착지',
      render: (flight: any, record: any) => formatFlight(record.flight).to,
    },
    {
      title: '가는날',
      render: (flight: any, record: any) => formatFlight(record.flight).fromDate,
    },
    {
      title: '오는날',
      render: (flight: any, record: any) => formatFlight(record.flight).toDate,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => (
        <Tooltip
          title={
            <>
              <div>라인 1: 네이버에서 공급사별 투어비스 순위</div>
              <div>라인 2: 네이버에서 카드별 투어비스 순위</div>
              <div>라인 3: 스카이스캐너에서 투어비스 순이</div>
            </>
          }
        >
          <span>변동 </span>
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      render: (ranks: any, record: any) => {
        const rankDelta: any = renderRankDelta(record.ranks);

        return {
          children: (
            <>
              <div style={{ color: renderRankDeltaStyle(rankDelta[0]).color }}>
                {renderRankDeltaStyle(rankDelta[0]).sign}
                {renderRankDeltaStyle(rankDelta[0]).text}
              </div>
              <div style={{ color: renderRankDeltaStyle(rankDelta[1]).color }}>
                {renderRankDeltaStyle(rankDelta[1]).sign}
                {renderRankDeltaStyle(rankDelta[1]).text}
              </div>
              <div style={{ color: renderRankDeltaStyle(rankDelta[2]).color }}>
                {renderRankDeltaStyle(rankDelta[2]).sign}
                {renderRankDeltaStyle(rankDelta[2]).text}
              </div>
            </>
          ),
        };
      },
    },
    {
      title: '오늘',
      render: (ranks: IRoom, record: IDataType) => ({
        props: {
          style: {
            textAlign: renderRankDayCell(record.ranks, 0)[0],
          },
        },
        children: (
          <>
            <div>
              {renderRankDayCell(record.ranks, 0)[1]}{' '}
              {renderRankDayCell(record.ranks, 0)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 0)[2]}{' '}
              {renderRankDayCell(record.ranks, 0)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 0)[3]}{' '}
              {renderRankDayCell(record.ranks, 0)[5] ? '' : <CustomWarningOutlined />}
            </div>
          </>
        ),
      }),
    },
    {
      title: 'Day 1',
      render: (ranks: IRoom, record: IDataType) => ({
        props: {
          style: {
            textAlign: renderRankDayCell(record.ranks, 1)[0],
          },
        },
        children: (
          <>
            <div>
              {renderRankDayCell(record.ranks, 1)[1]}{' '}
              {renderRankDayCell(record.ranks, 1)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 1)[2]}{' '}
              {renderRankDayCell(record.ranks, 1)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 1)[3]}{' '}
              {renderRankDayCell(record.ranks, 1)[5] ? '' : <CustomWarningOutlined />}
            </div>
          </>
        ),
      }),
    },
    {
      title: 'Day 2',
      render: (ranks: IRoom, record: IDataType) => ({
        props: {
          style: {
            textAlign: renderRankDayCell(record.ranks, 2)[0],
          },
        },
        children: (
          <>
            <div>
              {renderRankDayCell(record.ranks, 2)[1]}{' '}
              {renderRankDayCell(record.ranks, 2)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 2)[2]}{' '}
              {renderRankDayCell(record.ranks, 2)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 2)[3]}{' '}
              {renderRankDayCell(record.ranks, 2)[5] ? '' : <CustomWarningOutlined />}
            </div>
          </>
        ),
      }),
    },
    {
      title: 'Day 3',
      render: (ranks: IRoom, record: IDataType) => ({
        props: {
          style: {
            textAlign: renderRankDayCell(record.ranks, 3)[0],
          },
        },
        children: (
          <>
            <div>
              {renderRankDayCell(record.ranks, 3)[1]}{' '}
              {renderRankDayCell(record.ranks, 3)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 3)[2]}{' '}
              {renderRankDayCell(record.ranks, 3)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 3)[3]}{' '}
              {renderRankDayCell(record.ranks, 3)[4] ? '' : <CustomWarningOutlined />}
            </div>
          </>
        ),
      }),
    },
    {
      title: 'Day 4',
      render: (ranks: IRoom, record: IDataType) => ({
        props: {
          style: {
            textAlign: renderRankDayCell(record.ranks, 4)[0],
          },
        },
        children: (
          <>
            <div>
              {renderRankDayCell(record.ranks, 4)[1]}{' '}
              {renderRankDayCell(record.ranks, 4)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 4)[2]}{' '}
              {renderRankDayCell(record.ranks, 4)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 4)[3]}{' '}
              {renderRankDayCell(record.ranks, 4)[4] ? '' : <CustomWarningOutlined />}
            </div>
          </>
        ),
      }),
    },
    {
      title: 'Day 5',
      render: (ranks: IRoom, record: IDataType) => ({
        props: {
          style: {
            textAlign: renderRankDayCell(record.ranks, 5)[0],
          },
        },
        children: (
          <>
            <div>
              {renderRankDayCell(record.ranks, 5)[1]}{' '}
              {renderRankDayCell(record.ranks, 5)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 5)[2]}{' '}
              {renderRankDayCell(record.ranks, 5)[4] ? '' : <CustomWarningOutlined />}
            </div>
            <div>
              {renderRankDayCell(record.ranks, 5)[3]}{' '}
              {renderRankDayCell(record.ranks, 5)[5] ? '' : <CustomWarningOutlined />}
            </div>
          </>
        ),
      }),
    },
  ];
  const onChangePagination: PaginationProps['onChange'] = (page) => {
    setCurrentSkip((page - 1) * take);
  };

  const searchHandler = (store: StoreValue) => {
    setAirlineState(store.airline == null ? '' : store.airline);
    setToState(store.to == null ? '' : store.to);
    setSiteState(store.site == null ? 2 : store.site);

    navigate({
      pathname: '/data/rank',
      search: `?date=${date}&ampm=${ampm}&airline_id=${store.airline == null ? '' : store.airline}&to=${
        store.to == null ? '' : store.to
      }`,
    });
  };

  const handleAirlineSelected = (airlineRecord: string) => {
    const url = new URLSearchParams(formatFlight(airlineRecord)).toString();
    navigate({
      pathname: '/data',
      search: `?${url}&date=${date}&ampm=${ampm}`,
    });
  };

  useEffect(() => {
    dispatch(actionGetAirline());
    dispatch(actionGetFromTo(''));
  }, [dispatch]);

  useEffect(() => {
    if (airlines.length) {
      const airlinesTmp = cloneDeep(airlines);
      const newAirlines = airlinesTmp.map((newAirline) => ({
        value: newAirline.id,
        label: newAirline.name,
      }));
      setAirlineOptions(newAirlines);
    }
  }, [airlines]);

  useEffect(() => {
    dispatch(
      actionGetDetail({
        date,
        ampm: ampm.toLowerCase(),
        airline_id: airlineState,
        to: toState,
        take,
        skip: currentSkip,
      })
    );
  }, [dispatch, currentSkip, date, ampm, airlineState, siteState, toState]);

  useEffect(() => {
    const newDataSource = transformFlightRank(airlineRank, new Date(date));
    setDataSource(newDataSource);
  }, [airlineRank, date]);

  return (
    <>
      <PageHeader backIcon={null} title="여정정보" />
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Card>
          <Form form={form} layout="vertical" onFinish={searchHandler}>
            <Space className="h-full items-start">
              <Form.Item label="항공사" name="airline" className="w-[250px]">
                <Select
                  showSearch
                  allowClear
                  placeholder="항공사를 선택하세요"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={airlineOptions}
                />
              </Form.Item>
              <Form.Item label="도착지" name="to" className="w-[150px]">
                <Select
                  showSearch
                  allowClear
                  placeholder="도착지를 선택하세요"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={listTo}
                />
              </Form.Item>
              <Form.Item label={<span />}>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  검색
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>
        <Table
          onRow={(record) => ({
            onClick: () => {
              handleAirlineSelected(record.flight);
            },
          })}
          style={{ cursor: 'pointer' }}
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={false}
        />
        <Pagination
          style={{ textAlign: 'right' }}
          current={currentSkip / take + 1}
          onChange={onChangePagination}
          total={airlineRankTotal}
        />
      </Space>
    </>
  );
}
export default AirDetails;
