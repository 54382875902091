import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'store';
import request from 'util/request';

export interface IAirline {
  id: string;
  name: string;
  status: number;
  skyscanner_code: string;
}

export interface IInitStateAirline {
  listAirline: IAirline[];
  loading: boolean;
}

const initialState: IInitStateAirline = {
  listAirline: [],
  loading: false,
};

export const actionGetAirline = createAsyncThunk('airline/actionGetAirline', async (_params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/airline',
      method: 'GET',
    });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const slice = createSlice({
  name: 'airline',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionGetAirline.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionGetAirline.fulfilled, (state, action) => {
        state.loading = false;
        state.listAirline = get(action, 'payload.data');
      });
  },
});

export const selectLoading = (state: RootState) => state.airline.loading;

export const selectAirline = (state: RootState) => state.airline.listAirline;

export default slice.reducer;
