import { DatePicker, Space, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'store';
import { actionGetFlightBaseData, IFlightBaseData, selectFlightBaseData } from 'store/flightBaseDataSlice';
import { useEffect, useState } from 'react';
import { selectLoading } from 'store/airdetailSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import type { DatePickerProps } from 'antd';

function Rank() {
  const loading = useAppSelector(selectLoading);
  const flightBaseDatas = useAppSelector(selectFlightBaseData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectDate, setSelectDate] = useState('');
  const [dataDays, setDataDays] = useState<IFlightBaseData[]>([]);
  const generateParams = (record: string) => {
    const targetDate = record.split(' ')[0];
    const targetAmpm = record.split(' ')[1];
    return {
      date: targetDate,
      ampm: targetAmpm,
    };
  };
  const columns = [
    {
      title: 'Date',
      filteredValue: [selectDate],
      onFilter: (value: any, record: any) => record.date.includes(value),
      render: (created_time: string, record: any) => ({
        props: {
          style: {
            color: 'blue',
          },
        },
        children: record.date,
      }),
    },
  ];
  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (dateString) {
      setSelectDate(dateString);
    } else {
      setSelectDate('');
    }
  };
  useEffect(() => {
    dispatch(actionGetFlightBaseData({}));
  }, [dispatch]);

  useEffect(() => {
    const newDataDays: IFlightBaseData[] = [];
    const dayNotNull = flightBaseDatas.filter((item) => item.date);
    dayNotNull.forEach((item) => {
      newDataDays.push({
        date: `${moment(item.date).format('YYYY-MM-DD')} PM`,
      });
      newDataDays.push({
        date: `${moment(item.date).format('YYYY-MM-DD')} AM`,
      });
    });
    setDataDays(newDataDays);
  }, [flightBaseDatas]);

  const handleDateSelected = (record: { date: string }) => {
    const url = new URLSearchParams(generateParams(record.date));
    navigate({
      pathname: '/data/rank',
      search: `?${url}`,
    });
  };
  return (
    <Space direction="vertical" style={{ width: '50%', justifyContent: 'right' }}>
      <DatePicker size="small" onChange={onChange} style={{ marginTop: '10px' }} />
      <Table
        onRow={(record: { date: string }) => ({
          onClick: () => {
            handleDateSelected(record);
          },
        })}
        style={{ cursor: 'pointer' }}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataDays}
        columns={columns}
        bordered
        pagination={{ pageSize: 12 }}
      />
    </Space>
  );
}
export default Rank;
